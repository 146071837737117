import Index from '@/views/Index.vue'
import NotFound from '@/views/NotFound.vue'

/** @type {import('vue-router').RouterOptions['routes']} */
export const routes = [
  {
    path: '/',
    name: 'index',
    meta: { title: 'Index', layout: 'default' },
    component: Index,
  },
  { path: '/404', name: 'not-found', component: NotFound },
  { path: '/:path(.*)', component: NotFound },
]
