export default {
  language: 'Languages',
  appName: 'predictor',
  greeting: 'Hello world!',
  hello: 'Hello',
  location: 'Jakarta',
  home: 'Home',
  pageNotFound: 'Page Not Founnd',
  pageNotFoundDesc: 'The page you`re looking not found',
  pageNotFoundSubDesc: 'Please put the link correctly',
  backToHome: 'Kembali ke halaman utama',
}

// export default en
