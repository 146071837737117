import axios, { routePathAPI } from '@/plugins/axios.js'
import { helper as $h } from '@/utils/helper'

// initial state
const state = () => ({})

// getters
const getters = {}

// actions
const actions = {
  product({ commit }, payload) {
    let queryParameters = {
      page: payload.page ?? 1,
      ordering: payload.ordering ?? 'product_name',
      store_id: payload.store_id,
      limit: payload.limit ?? 50,
    }
    if (payload.search) queryParameters.search = payload.search

    return new Promise((resolve, reject) => {
      axios
        .get(
          `${routePathAPI()}/product-predictions/?` +
            $h.serializeObjectToQueryParam({ ...queryParameters })
        )
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  result({ commit }, payload) {
    let queryParameters = {
      page: payload.page ?? 1,
      limit: payload.limit ?? 50,
      ordering: payload.ordering,
      store_id: payload.store_id,
      prediction_period: payload.prediction_period,
      algorithm: payload.algorithm,
      algorithm_derivative: payload.algorithm_derivative,
    }
    if (payload.search) queryParameters.search = payload.search

    return new Promise((resolve, reject) => {
      axios
        .get(
          `${routePathAPI()}/prediction-result/?` +
            $h.serializeObjectToQueryParam({ ...queryParameters })
        )
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
}

// mutations
const mutations = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
