<template>
  <div class="h-full">
    <div class="flex h-full w-full flex-col overflow-y-auto bg-white">
      <div class="bg-app-light flex flex-col">
        <router-view :key="$route.fullPath" v-slot="{ Component }">
          <transition name="moveInUp">
            <component :is="Component" />
          </transition>
        </router-view>
      </div>
    </div>
  </div>
</template>

<script>
import globalMixin from '@/mixins/global.js'

export default {
  name: 'App',
  components: {},
  mixins: [globalMixin],
  data() {
    return {
      message: 'hello',
    }
  },
}
</script>
