import { colors } from '../themeConfig'

export default {
  inject: ['dayjs'],
  emits: [],
  data: () => ({
    appName: 'Prediksi Penjualan',
    userAgent: '',
  }),
  computed: {},
  mounted() {
    this.userAgent = navigator.userAgent
  },
  methods: {
    openRoute(routeName) {
      this.$router.push({
        name: routeName,
        query: { lang: this.$route.query.lang },
      })
    },
  },
}
