<template>
  <section
    id="content"
    class="flex flex-col bg-white"
    :class="
      noPadding ? 'w-full md:overflow-x-hidden' : 'container mx-auto px-4 py-8'
    "
  >
    <!-- component -->
    <div class="w-full">
      <div v-if="noTitle === false" class="mb-4 md:mb-6">
        <h2 class="text-md font-semibold leading-tight md:text-lg lg:text-xl">
          Prediksi Penjualan
        </h2>
      </div>
      <div class="flex w-full flex-col-reverse justify-between md:flex-row">
        <div class="my-2 flex flex-col-reverse md:flex-row">
          <div class="relative">
            <div class="mb-1 flex flex-row sm:mb-0">
              <div class="relative">
                <select
                  class="block h-full w-full appearance-none rounded-l-lg border border-gray-300 bg-white px-4 py-2 pr-8 text-xs leading-tight text-gray-700 focus:border-gray-500 focus:bg-white focus:outline-none md:text-sm"
                  @change="change"
                >
                  <option value="10" selected>10</option>
                  <option value="20">20</option>
                  <option value="30">30</option>
                  <option value="40">40</option>
                  <option value="50">50</option>
                </select>
              </div>
              <div class="relative">
                <select
                  class="block h-full w-full appearance-none rounded-r-lg border-b border-r border-t border-gray-300 bg-white px-4 py-2 pr-8 text-xs leading-tight text-gray-700 focus:border-l focus:border-r focus:border-gray-500 focus:bg-white focus:outline-none sm:rounded-r-none sm:border-r-0 md:text-sm"
                  @change="sorting"
                >
                  <option value="ASC">ASC</option>
                  <option value="DESC">DESC</option>
                </select>
              </div>
            </div>
          </div>
          <div class="relative mb-4 block md:mb-0">
            <span
              class="absolute inset-y-0 left-0 flex h-10 items-center pl-3 md:pl-2"
            >
              <svg
                viewBox="0 0 24 24"
                class="h-4 w-4 fill-current text-gray-500"
              >
                <path
                  d="M10 4a6 6 0 100 12 6 6 0 000-12zm-8 6a8 8 0 1114.32 4.906l5.387 5.387a1 1 0 01-1.414 1.414l-5.387-5.387A8 8 0 012 10z"
                ></path>
              </svg>
            </span>
            <input
              placeholder="Cari produk..."
              class="block w-full appearance-none rounded-l-lg rounded-r-lg border border-b border-gray-300 bg-white py-2.5 pl-10 pr-10 text-xs text-gray-700 placeholder-gray-400 focus:bg-white focus:text-gray-700 focus:placeholder-gray-600 focus:outline-teal-600 sm:rounded-l-none md:py-2 md:text-sm"
              @input="searchData"
            />
          </div>
        </div>
        <div
          class="mb-2 flex flex-row justify-between bg-white md:flex-col-reverse md:items-end md:justify-normal lg:mb-0 lg:flex-row lg:items-center"
        >
          <div class="inline-flex items-center md:mt-2 lg:mt-0">
            <span class="xs:text-sm text-xs text-gray-700 md:text-sm">
              Menampilkan {{ filterData.limit * (filterData.page - 1) + 1 }} -
              {{
                filterData.limit * filterData.page > productTotal
                  ? productTotal
                  : filterData.limit * filterData.page
              }}
              dari {{ productTotal }} data
            </span>
          </div>
          <div class="inline-flex w-40 justify-end">
            <button
              :disabled="hasPreviousPage === false || isLoading === true"
              class="md:text-md inline-flex items-center rounded-l-lg bg-gray-100 px-4 py-2 text-xs font-semibold text-gray-800 hover:bg-gray-200 md:text-sm"
              @click="previousData"
            >
              <svg
                v-if="previousPageClicked && isLoading"
                class="mr-2 h-3 w-3 animate-spin"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  class="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  stroke-width="4"
                ></circle>
                <path
                  class="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
              <span>Prev</span>
            </button>
            <button
              :disabled="hasNextPage === false || isLoading === true"
              class="md:text-md inline-flex items-center rounded-r-lg bg-gray-100 px-4 py-2 text-xs font-semibold text-gray-800 hover:bg-gray-200 md:text-sm"
              @click="nextData"
            >
              <svg
                v-if="nextPageClicked && isLoading"
                class="mr-2 h-3 w-3 animate-spin"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  class="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  stroke-width="4"
                ></circle>
                <path
                  class="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
              <span>Next</span>
            </button>
          </div>
        </div>
      </div>

      <div class="-mx-4 px-4 py-4 sm:-mx-8 sm:px-8">
        <!-- Scrolling container -->
        <div class="overflow-x-auto">
          <div
            class="inline-block min-w-full rounded-lg lg:overflow-hidden"
            :class="noShadow ? '' : 'shadow'"
          >
            <table class="min-w-max table-auto leading-normal sm:min-w-full">
              <thead>
                <tr>
                  <th
                    class="md:text-md border-b-2 border-gray-200 bg-gray-50 px-5 py-2 text-left text-xs font-semibold uppercase tracking-wider text-gray-600 md:py-3 md:text-sm"
                  >
                    NO
                  </th>
                  <th
                    class="md:text-md border-b-2 border-gray-200 bg-gray-50 px-5 py-2 text-left text-xs font-semibold uppercase tracking-wider text-gray-600 md:py-3 md:text-sm"
                  >
                    ID
                  </th>
                  <th
                    class="md:text-md border-b-2 border-gray-200 bg-gray-50 px-5 py-2 text-left text-xs font-semibold uppercase tracking-wider text-gray-600 md:py-3 md:text-sm"
                  >
                    Produk
                  </th>
                  <th
                    class="md:text-md border-b-2 border-gray-200 bg-gray-50 px-5 py-2 text-left text-xs font-semibold uppercase tracking-wider text-gray-600 md:py-3 md:text-sm"
                  >
                    <span class="hidden md:flex">PERKIRAAN PENJUALAN</span>
                    <span class="flex md:hidden">P. PENJUALAN</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <template v-if="isLoading">
                  <tr>
                    <td
                      rowspan="3"
                      colspan="3"
                      class="md:text-md text-cente2 border-b border-gray-200 bg-white px-5 py-5 text-sm"
                    >
                      <p class="whitespace-no-wrap text-gray-500">
                        {{ isSearch ? 'Mencari data...' : 'Load data...' }}
                      </p>
                    </td>
                  </tr>
                </template>
                <template v-else-if="productData.length !== 0">
                  <tr
                    v-for="(item, idx) in productData"
                    :key="item.product_stock_id"
                  >
                    <td
                      class="md:text-md border-b border-gray-200 bg-white px-3 py-3 text-xs md:px-5 md:py-4 md:text-sm"
                    >
                      <span
                        class="relative inline-block w-10 py-1 text-center font-semibold leading-tight text-gray-900"
                      >
                        <span
                          aria-hidden
                          class="absolute inset-0 rounded-lg bg-gray-200 text-lg opacity-50"
                        ></span>
                        <span class="relative">{{ idx + 1 }}</span>
                      </span>
                    </td>
                    <td
                      class="md:text-md border-b border-gray-200 bg-white px-3 py-3 text-xs md:px-5 md:py-4 md:text-sm"
                    >
                      <span
                        class="relative inline-block w-24 py-1 text-center font-semibold leading-tight text-teal-900"
                      >
                        <span
                          aria-hidden
                          class="absolute inset-0 rounded-lg bg-teal-200 opacity-50"
                        ></span>
                        <span class="relative">{{
                          item.product_stock_id.split('-')[0].toUpperCase()
                        }}</span>
                      </span>
                    </td>
                    <td
                      class="md:text-md border-b border-gray-200 bg-white px-5 py-3 text-xs md:py-4 md:text-sm"
                    >
                      <p class="whitespace-no-wrap tracking-wide text-gray-800">
                        {{ item.product_name }}
                      </p>
                    </td>
                    <td
                      class="md:text-md border-b border-gray-200 bg-white px-5 py-3 text-sm md:py-4"
                    >
                      <span
                        class="text-md relative inline-block w-12 py-1 text-center font-semibold leading-tight text-green-900"
                      >
                        <span
                          aria-hidden
                          class="absolute inset-0 rounded-lg bg-green-200 opacity-50"
                        ></span>
                        <span class="relative">{{
                          item.predicted_quantity
                        }}</span>
                      </span>
                    </td>
                  </tr>
                </template>
                <template v-else>
                  <tr>
                    <td
                      rowspan="3"
                      colspan="3"
                      class="md:text-md text-cente2 border-b border-gray-200 bg-white px-5 py-5 text-sm md:py-3"
                    >
                      <p class="whitespace-no-wrap text-gray-500">
                        Tidak ada data
                      </p>
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { onMounted, ref, reactive, toRefs } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { helper as $h } from '@/utils/helper'
import globalMixin from '@/mixins/global.js'

export default {
  name: 'HomePage',
  components: {},
  mixins: [globalMixin],
  setup() {
    const { t, n } = useI18n()
    const store = useStore()
    const route = useRoute()
    const router = useRouter()

    const storeId = ref('c7bfe8bb-27ce-423c-8fb1-af51136e86a7')
    const noPadding = ref(false)
    const noShadow = ref(false)
    const noTitle = ref(false)

    const isLoading = ref(false)
    const timer = ref(0)
    const status = ref('')
    const message = ref('')

    let messageAlert = reactive({
      show: false,
      message: '',
      type: 'info',
      position: 'center',
    })

    let metaData = reactive({
      count: 10,
      next: null,
      previous: null,
    })

    let filterData = reactive({
      page: 1,
      limit: 10,
      ordering: 'product_name',
      prediction_period: 'next_week',
      algorithm: 'exponential_smoothing',
      algorithm_derivative: 'simple',
      search: '',
      // type: null,
    })

    let productTotal = ref(0)
    let productData = ref([])
    let hasNextPage = ref(false)
    let hasPreviousPage = ref(false)
    let nextPageClicked = ref(false)
    let previousPageClicked = ref(false)
    let isSearch = ref(false)

    onMounted(async () => {
      if (route.query.store_id !== undefined) {
        storeId.value = route.query.store_id
      }

      if (route.query.no_padding !== undefined) {
        noPadding.value = route.query.no_padding === 'true' ? true : false
      }

      if (route.query.no_shadow !== undefined) {
        noShadow.value = route.query.no_shadow === 'true' ? true : false
      }

      if (route.query.no_title !== undefined) {
        noTitle.value = route.query.no_title === 'true' ? true : false
      }

      // getPredictions()
      getPredictionResult()
    })

    const getPredictions = async (reset) => {
      if (reset === true) {
        filterData.page = 1
        productData.value = []
      }

      if (filterData.page === 1) {
        isLoading.value = true
      }

      await store
        .dispatch('prediction/product', {
          page: filterData.page,
          ordering: filterData.ordering,
          store_id: storeId.value,
          limit: filterData.limit,
          search: filterData.search,
        })
        .then((response) => {
          console.log('---response ', response)

          if (response.results) {
            productData.value = [...productData.value, ...response.results]
          }

          // if (responseData.meta) {
          //   // metaData = responseData.meta
          //   Object.assign(metaData, responseData.meta)
          // }

          if (filterData.page === 1) {
            isLoading.value = false
          }

          const next = response.next
          if (next !== null) {
            hasNextPage.value = true
          } else {
            hasNextPage.value = false
          }

          const previous = response.previous
          if (previous !== null) {
            hasPreviousPage.value = true
          } else {
            hasPreviousPage.value = false
          }
        })
        .catch((e) => {
          if (filterData.page === 1) {
            isLoading.value = false
          }
          console.log(e)
        })
    }

    const getPredictionResult = async (reset) => {
      if (reset === true) {
        filterData.page = 1
        productData.value = []
      }

      isLoading.value = true

      await store
        .dispatch('prediction/result', {
          page: filterData.page,
          limit: filterData.limit,
          ordering: filterData.ordering,
          prediction_period: filterData.prediction_period,
          algorithm: filterData.algorithm,
          algorithm_derivative: filterData.algorithm_derivative,
          store_id: storeId.value,
          search: filterData.search,
        })
        .then((response) => {
          productTotal.value = response.count

          if (response.results) {
            productData.value = response.results
          }

          isLoading.value = false

          const next = response.next
          if (next !== null) {
            hasNextPage.value = true
          } else {
            hasNextPage.value = false
          }

          const previous = response.previous
          if (previous !== null) {
            hasPreviousPage.value = true
          } else {
            hasPreviousPage.value = false
          }
        })
        .catch((e) => {
          if (filterData.page === 1) {
            isLoading.value = false
          }
          console.log(e)
        })
    }

    const change = async (event) => {
      filterData.limit = Number(event.target.value)
      getPredictionResult()
    }

    const sorting = async (event) => {
      if (event.target.value === 'ASC') {
        filterData.ordering = 'product_name'
      } else {
        filterData.ordering = '-product_name'
      }
      getPredictionResult()
    }
    const nextData = async () => {
      nextPageClicked.value = true
      filterData.page += 1
      await getPredictionResult()
      nextPageClicked.value = false
    }

    const previousData = async () => {
      previousPageClicked.value = true
      filterData.page -= 1
      await getPredictionResult()
      previousPageClicked.value = false
    }

    const searchData = async (event) => {
      filterData.search = event.target.value

      clearTimeout(timer.value)
      timer.value = setTimeout(async () => {
        isSearch.value = true
        await getPredictionResult(true)
        isSearch.value = false
      }, 1000)
    }

    return {
      t,
      n,
      store,
      route,
      router,

      noPadding,
      noShadow,
      noTitle,

      isLoading,
      timer,
      status,
      message,

      metaData,
      filterData,

      productData,
      productTotal,

      hasNextPage,
      hasPreviousPage,
      nextPageClicked,
      previousPageClicked,
      nextData,
      previousData,

      change,
      sorting,
      isSearch,
      searchData,

      getPredictions,
      getPredictionResult,

      messageAlert,
    }
  },
  data: () => ({}),
  computed: {},
  methods: {},
}
</script>
