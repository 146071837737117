import axios from 'axios'

export function routePathAPI() {
  return '/api'
}

axios.defaults.baseURL = import.meta.env.VITE_API_URL

const instanceAxios = axios.create({
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  },
})

export default instanceAxios
