import { createStore } from 'vuex'
import global from './global'
import prediction from './prediction'

export default createStore({
  modules: {
    global,
    prediction,
  },
})
