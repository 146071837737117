import { createApp } from 'vue'
import { createPinia } from 'pinia'
import store from '@/store/vuex'

import App from './App.vue'
import i18n from './plugins/i18n.js'
import axios from './plugins/axios.js'
import utils from './utils'

import { createRouter, createWebHistory } from 'vue-router'
import { routes } from './routes.js'
import { LANGUAGES } from './constants'

import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'

import Vuesax from 'vuesax3'
import 'vuesax3/dist/vuesax.css'

// import 'swiper/css/scrollbar'
import './assets/css/tailwind.css'
import './assets/scss/main.scss'

import { colors } from './themeConfig'

const app = createApp(App)
dayjs.extend(utc)
dayjs.extend(timezone)

const router = createRouter({
  history: createWebHistory(),
  routes,
})

router.beforeEach((to, from, next) => {
  // use the language from the routing param or default language
  let language = to.query.lang
  if (!LANGUAGES.includes(language)) {
    language = LANGUAGES[0]
  }
  const { locale } = i18n.global
  locale.value = language

  next()
})

function setDeviceWidth(width) {
  if (width < 768) {
    return 'w-full'
  } else {
    return 'w-phone'
  }
}

app.use(Vuesax, { theme: { colors } })
app.use(store)
app.use(createPinia())
app.use(i18n)
app.use(router)

app.provide('axios', axios) // provide axios
app.provide('dayjs', dayjs) // provide dayJS

app.provide('deviceWidth', setDeviceWidth(window.innerWidth))


utils(app)

app.mount('#app')
